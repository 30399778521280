import React from 'react';
import { theme } from 'theme';

const textVariantObj = {
    dark: theme.textColor.heading,
    light: theme.textColor.headingLight,
    muted: theme.textColor.muted
};

interface Props {
    style?: object;
    children: string | object;
    textVariant?: 'dark' | 'light';
    mb?: number;
    mt?: number;
    pb?: number;
    pt?: number;
}

export const H2 = (
    {
        children,
        textVariant,
        mb,
        pb,
        mt,
        pt,
        ...props
    }: Props) => {
    const atomStyles = {
        mb: theme.spacing[mb] || '0px',
        mt: theme.spacing[mt] || '0px',
        pb: theme.spacing[pb] || '0px',
        pt: theme.spacing[pt] || '0px',
        color: !textVariant ? theme.textColor.heading : textVariantObj[textVariant]
    };

    return (
        <h2 data-comp={H2.displayName} {...props}>
            <>
                {children}
            </>
            <style jsx>{`
                h2 {
                    margin-top: ${atomStyles.mt};
                    margin-bottom: ${atomStyles.mb};
                    padding-top: ${atomStyles.pt};
                    padding-bottom: ${atomStyles.pb};
                    color: ${atomStyles.color};
                    font-family: ${theme.font.primary};
                    font-size: 44px;
                    line-height: 48.4px;
                    @media (max-width: 575.98px) {
                        font-size: 39.06px;
                        line-height: 42.96px;
                    }
                }
            `}</style>
        </h2>
    );
};

H2.displayName = 'H2';
