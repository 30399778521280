import React from 'react';
import { theme } from 'theme';

const textVariantObj = {
    dark: theme.textColor.heading,
    light: theme.textColor.headingLight,
    muted: theme.textColor.muted
};

interface Props {
    style?: object;
    children: string | object;
    textVariant?: 'dark' | 'light';
    mb?: number;
    mt?: number;
    pb?: number;
    pt?: number;
    className?: any;
}

export const H4 = (
    {
        children,
        textVariant,
        mb,
        pb,
        mt,
        pt,
        ...props
    }: Props) => {
    const atomStyles = {
        mb: theme.spacing[mb] || '0px',
        mt: theme.spacing[mt] || '0px',
        pb: theme.spacing[pb] || '0px',
        pt: theme.spacing[pt] || '0px',
        color: !textVariant ? theme.textColor.heading : textVariantObj[textVariant]
    };

    return (
        <h4 data-comp={H4.displayName} {...props}>
            <>
                {children}
            </>
            <style jsx>{`
                h4 {
                    margin-top: ${atomStyles.mt};
                    margin-bottom: ${atomStyles.mb};
                    padding-top: ${atomStyles.pt};
                    padding-bottom: ${atomStyles.pb};
                    color: ${atomStyles.color};
                    font-family: ${theme.font.primary};
                    font-size: 31.25px;
                    line-height: 37.5px;
                    @media (max-width: 575.98px) {
                        font-size: 25px;
                        line-height: 32.5px;
                    }
                }
            `}</style>
        </h4>
    );
};

H4.displayName = 'H4';
