export const theme = {
    font: {
        // primary:
        //     '"Avenir", Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif',
        // secondary:
        //     '"Avenir", Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif'
        primary:
            'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif',
        secondary:
            'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif'
    },
    textColor: {
        heading: '#4F4F4F',
        headingLight: '#F6FCFF',
        body: '#080F28',
        bodyLight: '#666666',
        mono: '#0A2834',
        monoLight: '#F6FCFF',
        muted: '#666666'
    },
    colors: {
        primary: '#4ABC75',
        secondary: '#219EBC',
        tertiary: '#FB8500'
    },
    spacing: {
        0: '0px',
        1: '2px',
        2: '4px',
        3: '8px',
        4: '16px',
        5: '24px',
        6: '32px',
        7: '40px',
        8: '48px',
        9: '56px',
        10: '64px'
    },
    buttons: {
        primary: {
            backgroundColor: 'linear-gradient(135deg, #E637A8 2.69%, #E68037 75%)',
            textColor: '#fff',
            shadowColor: '#FB8500',
            borderColor: '#0A2834',
            activeColor: 'linear-gradient(180deg, #E637A8 0%, #D11F4A 100%)'
        },
        secondary: {
            backgroundColor: 'linear-gradient(180deg, #1CC8E1 0%, #1C9AE1 100%)',
            textColor: '#fff',
            shadowColor: '#4ABC75',
            borderColor: '#023047',
            activeColor: 'linear-gradient(180deg, #1C9AE1 0%, #0C6CA2 100%)'
        }
    }
};
