import React from 'react';
import { theme } from 'theme';
import Link from 'next/link';

// Const textVariantObj = {
//   dark: theme.textColor.body,
//   light: theme.textColor.bodyLight,
//   muted: theme.textColor.muted
// }

interface Props {
    className?: string;
    style?: any;
    onClick?: any;
    children: string | object;
    href?: string;
    ctaVariant?: 'primary' | 'secondary';
    id?: string;
    to?: string;
    mb?: number;
    mt?: number;
    pb?: number;
    pt?: number;
}

export const Button = React.forwardRef((
    {
        children,
        ctaVariant,
        href,
        mb,
        pb,
        mt,
        pt,
        ...props
    }: Props, ref) => {
    const atomStyles = {
        mb: theme.spacing[mb] || '0px',
        mt: theme.spacing[mt] || '0px',
        pb: theme.spacing[pb] || '0px',
        pt: theme.spacing[pt] || '0px'
    };

    const ctaVariantObj = {
        primary: {
            backgroundColor: theme.buttons.primary.backgroundColor,
            textColor: theme.buttons.primary.textColor
        },
        secondary: {
            backgroundColor: theme.buttons.secondary.backgroundColor,
            textColor: theme.buttons.secondary.textColor
        }
    };

    const button = (
        <button
            // ref={ref}
            className='atoms-button'
            data-comp={Button.displayName}
            {...props}
            style={props.style}
        >
            <>
                {children}
            </>
            <style jsx>{`
                button {
                    text-decoration: none;
                    text-transform: uppercase;
                    padding: 0 30px;
                    height: 40px;
                    min-width: ${props?.style?.width ? '0px' : '140px'};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    //filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
                    color: ${ctaVariant
                            ? theme.buttons[ctaVariant].textColor
                            : theme.buttons.primary.textColor};
                    background: ${ctaVariant
                            ? theme.buttons[ctaVariant].backgroundColor
                            : theme.buttons.primary.backgroundColor};
                    border: none;
                    border-radius: 100px 100px 100px 100px;
                    outline: none;
                    font-weight: 700;
                    font-family: ${theme.font.secondary};
                    font-size: 16px;
                    text-align: center;
                    margin-top: ${atomStyles.mt};
                    margin-bottom: ${atomStyles.mb};
                    padding-top: ${atomStyles.pt};
                    padding-bottom: ${atomStyles.pb};
                    @media (max-width: 575.98px) {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &:hover {
                        background: ${ctaVariant == 'secondary' ? ' #1C9AE1' : '#E637A8'};
                    }

                    &:active {
                        background: ${ctaVariant
                                ? theme.buttons[ctaVariant].activeColor
                                : theme.buttons.primary.activeColor};
                    }

                    &:focus {
                        box-shadow: 0px 0px 8px 2px #1cc8e1;
                    }
                }
            `}</style>
        </button>
    );
    if (href) {
        return <Link href={href}>{button}</Link>;
    }
    return button;
});

Button.displayName = 'Button';
