import React from 'react';
import { SmallHeading } from '@/components/Views/Heading';
import { ChangeHandler, RefCallBack } from 'react-hook-form';

export const ErrorMessage = ({ error, className = '', requiredText = null }) => {
    if (!error)
        return null;
    const { type, message } = error;
    console.log('error type', type);

    let text = '';
    if (type === 'required') {
        text = requiredText ?? 'This is required';
        console.log('error required:', text);
    } else if (message) {
        text = message;
        console.log('error other:', message, type);
    } else {
        text = type;
        console.log('error unknown:', type);
    }
    return (
        <div className={'my-2 text-red-700 ' + className}>
            * {text}
        </div>
    );
};

/*
export type ChangeValueHandler = (value: any) => any;

interface InputProps {
    className?: string;
    inputClassName?: string;
    labelClassName?: string;
    label?: string;
    type?: string;
    //// React Hook Form
    errors?: any;

    onChangeValue?: ChangeValueHandler;

    value?: any;
    // this is for registering the input with react-hook-form
    // {...UseFormRegisterReturn}
    // export type UseFormRegisterReturn<TFieldName extends InternalFieldName = InternalFieldName> = {
    onChange?: ChangeHandler;
    onBlur?: ChangeHandler;
    // ref: RefCallBack;
    name?: string;
    min?: string | number;
    max?: string | number;
    maxLength?: number;
    minLength?: number;
    pattern?: string;
    required?: boolean;
    disabled?: boolean;

}

export const Input = React.forwardRef(({ className = '', inputClassName = '', label = null, errors = null, onChangeValue = null, ...props }: InputProps, ref: RefCallBack = null) => {
    const hasError = Boolean(errors?.[props.name]);
    const errorClass = hasError ? ' border-red-500 ' : '';
    const ringClass = ' focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40 ';
    // const styleClass = ' text-[#6B7280] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] ';
    const styleClass = ' px-4 py-2 mt-2 text-[#6B7280] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md ';
    // const styleClass = ' border-solid border-[#a2a2a2] rounded-lg px-[20px] py-[6px] ';
    // const styleClass = ' bg-red-200 ';

    return (
        <div className={className}>
            <div className={'w-full flex flex-col ' + errorClass}>
                <SmallHeading as='h4' className='text-left'>
                    {label}
                </SmallHeading>
                <input
                    ref={ref}
                    className={`
                        ${inputClassName}
                        ${errorClass}
                        ${ringClass}
                        ${styleClass}
                    `}
                    onChange={(e) => {
                        if (onChangeValue)
                            onChangeValue(e.target.value);
                        if (props.onChange)
                            return props.onChange(e);
                        return null;
                    }}
                    {...props}
                    // name={name}
                    // value={value}
                    // onChange={(event) => {
                    //     if (onChange) onChange(event.target.value);
                    // }}
                />
                <ErrorMessage error={errors?.[props.name]} />
                {/!*
                {errors && errors[props.name] && (
                    <span className='mt-10 pt-10 text-red-500'>
                        * This field is required
                    </span>
                )}
                *!/}
            </div>
        </div>
    );
});

Input.displayName = 'Input';

export const InputContainer = (props) => {
    return (
        <div className='input-container w-full'>
            {props.children}
            <style jsx>{`

                .form-container {
                    display: flex;
                    width: 100%;
                    background: blue;
                }

                //.input-container {
                //    display: flex;
                //    width: 100%;
                //    background: green;
                //}
            `}

            </style>
        </div>
    );
};

export const FormContainer = (props) => {
    return (
        <div className='form-container w-full'>
            {props.children}
            <style jsx>{`

                .form-container {
                    display: flex;
                    width: 100%;
                    //background: blue;
                }

                .input-container {
                    display: flex;
                    width: 100%;
                    //background: green;
                }
            `}

            </style>
        </div>
    );
};

export const Form = ({ children = null, className = '', fields = null, onSubmit = null }) => {

    // const {
    //     register,
    //     trigger,
    //     handleSubmit,
    //     watch,
    //     formState: { errors: formErrors }
    // } = useForm();
    //
    return (
        <form onSubmit={onSubmit}>
            <div className={'flex flex-col justify-center items-start ' + className}>
                {children}
                {fields?.map((field, index) => (
                    <Input
                        key={index}
                        label={field.label}
                        {...register(field.name, { required: true })}
                        // name={field.name}
                        // value={field.value}
                        // onChange={field.onChange}
                    />
                ))}
            </div>
        </form>
    );
};
*/
