import React from 'react';
import { useDispatchUi } from 'context/ui';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export const VideoPlayer = ({ url, play }) => {
    const dispatch: any = useDispatchUi();
    const [playing, setPlaying] = React.useState(false);

    // React.useEffect(() => {
    //     // create a timer
    //     const timer = setTimeout(() => {
    //         setPlaying(true);
    //     }, 100);
    // }, []);
    // React.useEffect(() => {
    //     console.log('video', url, 'playing: ', play);
    //     setPlaying(play);
    // }, [play, url]);

    return (
        <div
            className='vp'
            onClick={() => dispatch({ type: 'CLOSE_VIDEO' })}
            style={{ visibility: play ? 'visible' : 'hidden' }}
        >
            <div
                className='vp__wrapper'
                onClick={() => dispatch({ type: 'CLOSE_VIDEO' })}
            >
                <div
                    className='close-button'
                    style={{
                        position: 'absolute',
                        right: 0,
                        zIndex: 90,
                        color: '#fff',
                        fontFamily: 'Arial',
                        backgroundColor: '#222',
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        fontSize: '30px',
                        border: '2px solid #fff'
                    }}
                    onClick={() => dispatch({ type: 'CLOSE_VIDEO' })}
                >
                    &times;
                </div>
                <div className='aspect-video m-[16px]'>
                    <ReactPlayer
                        // className='react-player'
                        // playing={playing && play}
                        playing={play}
                        url={url}
                        width='100%'
                        height='100%'
                        controls={true}
                        playsinline={true}
                        // onReady={() => setPlaying(play)}
                        // transparent={false}
                        // responsive={false}
                    />
                </div>
            </div>
            <div
                id='overlay'
                onClick={() => dispatch({ type: 'CLOSE_VIDEO' })}
            />
            <style jsx>
                {`
                    .vp {
                        .vp-center {
                            background-color: #333;
                        }

                        min-height: 100vh;
                        position: fixed;
                        width: 100vw;
                        z-index: 90;
                        left: 0;
                        top: 0;

                        #overlay {
                            position: fixed;
                            min-height: 100vh;
                            width: 100%;
                            left: 0;
                            top: 0;
                            background-color: rgba(0, 0, 0, 0.6);
                            z-index: 89;
                            animation: fadein 300ms ease-out;
                        }

                        .vp__wrapper {
                            z-index: 90;
                            position: fixed;
                            left: 50%;
                            transform: translate(-50%, 4%);
                            width: 96%;
                            max-width: 1100px;
                        }
                    }

                    @media (max-width: 482px) {
                        .vp {
                            .vp__wrapper {
                                max-height: 400px;
                                top: 20%;

                                .close-button {
                                }
                            }
                        }
                    }

                    @keyframes fadein {
                        0% {
                            opacity: 0;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                `}
            </style>
        </div>
    );
};
