import React, { useEffect, useState } from 'react';

import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';

import { useRouter } from 'next/router';
import NewNavbar from '@/components/NewNavbar/NewNavbar';
import { NewMobileNav } from "@/components/NewNavbar/NewMobileNav";

export const Header = ({
    showClassCodeInput,
    showLoginAndSignup,
    signupLink,
    signupLinkText,
    hideNavigationMenu,
    stepUpNav,
    site,
    navbarComponent = null,
    navbarComponentMobile = null,
    timesTables = false
}) => {
    const { asPath } = useRouter();
    // console.log('Router as Path:', asPath);
    const [currentPath, setCurrentPath] = useState(null);

    useEffect(() => {
        // console.log('Setting current path', asPath);
        setCurrentPath(asPath);
    }, [asPath]);

    // UseLayoutEffect(() => {
    //   if (!process.browser)
    //     return null;
    //   setCurrentPath(window.location.pathname);
    // });

    // useEffect(() => {
    //   if (process.browser) {
    //     console.log("Setting current path:", window.location.pathname);
    //     setCurrentPath(window.location.pathname);
    //   }
    // }, [process.browser]);

    return (
        <div data-comp={Header.displayName}>
            <div id='desktop-nav-wrapper'>
                {navbarComponent ?? (
                    <NewNavbar
                        currentPath={currentPath}
                        showClassCodeInput={showClassCodeInput}
                        showLoginAndSignup={showLoginAndSignup}
                        signupLink={signupLink}
                        signupLinkText={signupLinkText}
                        hideNavigationMenu={hideNavigationMenu}
                        stepUpNav={stepUpNav}
                        site={site}
                        timesTables={timesTables}
                    />
                )}
            </div>

            <div id='mobile-nav-wrapper'>
                {navbarComponentMobile ?? <NewMobileNav currentPath={currentPath} stepUpNav={stepUpNav} />}
            </div>

            {/*
            <div id='mobile-nav-wrapper'>
                {navbarComponentMobile ?? (
                    <NewNavbar
                        currentPath={currentPath}
                        showClassCodeInput={showClassCodeInput}
                        showLoginAndSignup={showLoginAndSignup}
                        signupLink={signupLink}
                        signupLinkText={signupLinkText}
                        hideNavigationMenu={hideNavigationMenu}
                        stepUpNav={stepUpNav}
                        site={site}
                    />
                )}
            </div>
            */}

            <style jsx>
                {`
                    #desktop-nav-wrapper {
                        display: none;
                    }

                    @media (min-width: 1024px) {
                        #desktop-nav-wrapper {
                            display: block;
                        }
                    }

                    @media (min-width: 1024px) {
                        #mobile-nav-wrapper {
                            display: none;
                        }
                    }
                `}
            </style>
        </div>
    );
};

Header.displayName = 'Header';
