import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'atoms';
import { useRouter } from 'next/router';
import qs from 'qs';
import { openJoinPage } from '@/lib/muzology';

interface Props {
    handleSubmit: Function;
}

export const SignupForm = ({ handleSubmit }: Props) => {
    const router = useRouter();
    const [joinCode, setJoinCode] = useState('');
    const {
        register,
        handleSubmit: handleFormSubmit,
        watch,
        formState,
        getValues,
        setValue
    } = useForm({
        defaultValues: {
            job_role: 'teacher',
            name: '',
            email: ''
        }
    });
    const jobRole = watch('job_role', 'teacher');

    const { errors } = formState;

    const onSubmit = (data) => {
        const args = {
            role: data.job_role,
            name: data.name,
            email: data.email
        };
        // TODO: submit to hubspot

        // route to the correct page
        if (data.job_role === 'parent') {
            // redirect to subscription pricing page
            router.push(`/checkout?${qs.stringify(args)}`, undefined, { shallow: true });
        } else {
            // redirect to trial signup form
            router.push(`/signup?${qs.stringify(args)}`, undefined, { shallow: true });
        }
    };

    // @ts-ignore
    return (
        <form onSubmit={handleFormSubmit(onSubmit)}>
            <div className='signup-form'>
                <div className='signup-form--wrapper'>
                    <h4 className='signup-form--iam'>Sign Up As:</h4>

                    <div className='signup-form--radios-group custom-radios-group'>
                        <label className={`custom-radio ${jobRole === 'teacher' ? 'radio-checked' : ''}`} onClick={() => setValue('job_role', 'teacher')}>
                            An Educator
                        </label>
                        <label className={`custom-radio ${jobRole === 'parent' ? 'radio-checked' : ''}`} onClick={() => setValue('job_role', 'parent')}>
                            A Parent
                        </label>
                        {/*
						<label
							className={`custom-radio ${
								jobRole === 'student' ? 'radio-checked' : ''
							}`}
							onClick={() => setValue('job_role', 'student')}
						>
              A Student
						</label>
						*/}
                    </div>

                    {jobRole !== 'student' ? (
                        <div className='signup-form--form'>
                            <div className='signup-form--inputs'>
                                <div>
                                    <input
                                        placeholder='Your Name'
                                        {...register('name', { required: true })}
                                        // OnChange={(e: any) => handleChange('name', e.target.value)}
                                        // name='name'
                                        // value={formData.name}
                                    />
                                    {errors.name && <div className='error-message'>* This Field Is Required</div>}
                                </div>
                                <div>
                                    <input
                                        placeholder={jobRole === 'teacher' || jobRole === 'Admin' ? 'Your Faculty Email' : 'Your Email'}
                                        {...register('email', { required: true })}
                                        // OnChange={(e: any) => handleChange('email', e.target.value)}
                                        // name='email'
                                        // value={getValues('email')}
                                        type='email'
                                    />
                                    {errors.email && <div className='error-message'>* This Field Is Required</div>}
                                </div>
                            </div>
                            <div className='signup-form--submit-btn'>
                                <Button
                                    // @ts-ignore
                                    type='submit'
                                    style={{ height: '50px', width: '100%' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        // HandleSubmit(formData);
                                        handleFormSubmit(onSubmit)();
                                    }}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className='signup-form--form' style={{ margin: '24px 0 0 0' }}>
                            <p className='signup-form--Student-copy'>
                                We're so excited for you to use Muzology. To get started, your Teacher or Parent has to create an account first.
                            </p>
                            <p className='signup-form--Student-copy'>If your Teacher has given you a Class Join Code, enter it below.</p>
                            <div className='signup-form--Student-join-code'>
                                <input placeholder='Enter Class Join Code' onChange={(e) => setJoinCode(e.target.value)} name='join_code' value={joinCode} />
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '2px',
                                        right: '2px',
                                        width: '52px',
                                        minWidth: '52px',
                                        height: '36px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openJoinPage(joinCode);
                                    }}
                                >
                                    GO
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <style jsx>
                    {`
                        .signup-form {
                            .signup-form--wrapper {
                                .signup-form--iam {
                                    font-weight: 800;
                                    font-size: 36px;
                                    line-height: 49px;
                                    color: #ffffff;
                                    margin: 0;

                                    @media (max-width: 580px) {
                                        text-align: center;
                                    }
                                }

                                .error-message {
                                    color: red;
                                    height: 40px;
                                    line-height: 40px;
                                }

                                .signup-form--form {
                                    .signup-form--inputs {
                                        display: flex;
                                        align-items: center;

                                        width: calc(100% + 16px);
                                        margin: 0 -8px;
                                        padding-bottom: 20px;

                                        > div {
                                            width: 100%;
                                            margin: 10px 8px;
                                        }

                                        input {
                                            display: block;
                                            width: 100%;
                                            border-radius: 25px;
                                            height: 50px;
                                            border: none;
                                            padding-left: 20px;
                                            font-weight: 800;
                                            font-size: 18px;
                                            line-height: 25px;
                                            display: flex;
                                            align-items: center;
                                            color: #4f4f4f;

                                            &::placeholder {
                                                opacity: 0.5;
                                            }
                                        }
                                    }

                                    .signup-form--submit-btn {
                                        width: 175px;
                                        margin: 0 0 28px auto;
                                    }

                                    .signup-form--Student-copy {
                                        font-weight: 800;
                                        font-size: 18px;
                                        line-height: 25px;
                                        color: #ffffff;
                                        margin: 0 0 12px 0;
                                    }

                                    .signup-form--Student-join-code {
                                        max-width: 264px;
                                        margin: 26px auto 0;
                                        position: relative;
                                        height: 40px;

                                        input {
                                            height: 100%;
                                            width: 100%;
                                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                            border-radius: 100px;
                                            padding: 10px 22px;
                                            font-weight: 900;
                                            font-size: 16px;
                                            line-height: 19px;
                                            display: flex;
                                            align-items: center;
                                            text-transform: capitalize;
                                            color: #838383;

                                            &::placeholder {
                                                opacity: 0.5;
                                            }
                                        }

                                        button {
                                            position: absolute;
                                            top: 2px;
                                            right: 2px;
                                            width: 52px !important;
                                            min-width: 52px;
                                            height: 36px;
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-width: 680px) {
                            .signup-form {
                                .signup-form--wrapper {
                                    .signup-form--iam {
                                        text-align: center;
                                    }

                                    .signup-form--form {
                                        .signup-form--inputs {
                                            flex-direction: column;
                                            margin: 0;
                                            padding-bottom: 18px;
                                            width: 100%;
                                        }

                                        .signup-form--submit-btn {
                                            width: 100%;
                                        }

                                        .signup-form--Student-join-code {
                                            margin-bottom: 28px;
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    `}
                </style>
            </div>
        </form>
    );
};
