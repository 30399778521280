import React from 'react';
import { theme } from 'theme';

const textVariantObj = {
    dark: theme.textColor.body,
    light: theme.textColor.bodyLight,
    muted: theme.textColor.muted
};

interface Props {
    style?: object;
    children: string | object;
    textVariant?: 'dark' | 'light';
    mb?: number;
    mt?: number;
    pb?: number;
    pt?: number;
}

export const Text = (
    {
        children,
        textVariant,
        mb,
        pb,
        mt,
        pt,
        ...props
    }: Props) => {
    const atomStyles = {
        mb: theme.spacing[mb] || '0px',
        mt: theme.spacing[mt] || '0px',
        pb: theme.spacing[pb] || '0px',
        pt: theme.spacing[pt] || '0px',
        color: !textVariant ? theme.textColor.body : textVariantObj[textVariant]
    };

    return (
        <div data-comp={Text.displayName} {...props}>
            <>
                {children}
            </>
            <style jsx>{`
                div {
                    /* default <p> style */
                    display: block;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0;
                    margin-inline-end: 0;

                    margin-top: ${atomStyles.mt};
                    margin-bottom: ${atomStyles.mb};
                    padding-top: ${atomStyles.pt};
                    padding-bottom: ${atomStyles.pb};
                    color: ${atomStyles.color};
                    font-family: ${theme.font.secondary};
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    @media (max-width: 575.98px) {
                        font-size: 15px;
                        line-height: 22.5px;
                    }
                }
            `}</style>
        </div>
    );
};

Text.displayName = 'Text';
