import axios from 'axios';
import { parseName } from 'utils/parsename';

export const submit_email_form = async (formData) => {
    // function for form card submit if job role is teacher, parent, or admin
    const parsedName = parseName(formData.name, []);
    // const { firstName, lastName } = parsedName;
    const portalId = '8464088';
    const formGuid = 'd1a9baf6-72db-444f-ab24-0ca29594590c';
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const res = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
            portalId,
            formGuid,
            fields: [
                // firstname and lastname don't exist on this form...
                // {
                //   name: 'firstname',
                //   value: firstName,
                // },
                // {
                //   name: 'lastname',
                //   value: lastName,
                // },
                {
                    name: 'email',
                    value: formData.email
                },
                {
                    name: 'job_role',
                    value: formData.job_role
                }
            ]
        },
        config
    );
    return res;
};

