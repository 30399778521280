import React from 'react';
import { useRouter } from 'next/router';
import { useDispatchUi } from 'context/ui';
import { SignupForm } from 'components/SignupForm';
import { submit_email_form } from 'utils/hubspotFormSubmit';
import { openJoinPage } from '../../lib/muzology';

export const SignupModal = () => {
	const dispatch: any = useDispatchUi();
	const router = useRouter();

	const handleSubmit = async formData => {
		const { job_role, email, name } = formData;
		if (
			formData.job_role === 'Student'
      && formData.join_code
      && process.browser
		) {
			openJoinPage(formData.join_code);
		} else {
			try {
				const res = await submit_email_form(formData);
				console.log(res.data);
				if (job_role === 'Teacher' || job_role === 'Admin') {
					router.push(`/teachers?email=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}#signup`);
					dispatch({ type: 'CLOSE_ALL' });
				}

				if (job_role === 'Parent') {
					router.push(`/parents?email=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}#signup`);
					dispatch({ type: 'CLOSE_ALL' });
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	return (
		<div className="sm">
			<div className="sm--card">
				<img
					id="close-btn"
					src="/assets/icons/ion-close.svg"
					onClick={() => dispatch({ type: 'CLOSE_ALL' })}
					alt={'close'}
				/>
				<p className="sm--card-title">
          Get started and discover the power of music-based learning today.
				</p>

				<SignupForm handleSubmit={handleSubmit} />
			</div>
			<div id="overlay" onClick={() => dispatch({ type: 'CLOSE_ALL' })}/>
			<style jsx>
				{`
          .sm {
            min-height: 100vh;
            position: fixed;
            width: 100vw;
            z-index: 90;
            left: 0;
            top: 0;

            #overlay {
              position: fixed;
              min-height: 100vh;
              width: 100%;
              left: 0;
              top: 0;
              background-color: rgba(0, 0, 0, 0.6);
              z-index: 89;
              animation: fadein 300ms ease-out;
            }

            .sm--card {
              padding: 60px 55px 26px;
              color: #fff;
              background-color: #121651;
              z-index: 90;
              position: fixed;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -60%) scale(1);
              animation: grow 100ms linear;
              width: 96%;
              max-width: 765px;
              border-radius: 18px;
              background-image: url('/assets/backgrounds/modal-bg.svg');
              background-size: cover;
              background-position: center;

              #close-btn {
                position: absolute;
                top: 23px;
                right: 31px;
                width: 40px;
                height: 40px;
                cursor: pointer;
              }

              .sm--card-title {
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                color: #ffffff;
                margin: 0 0 26px 0;
                padding: 0 30px 0 0;
              }
            }
          }
          @media (max-width: 680px) {
            .sm {
              .sm--card {
                padding: 32px 32px 4px;

                .sm--card-title {
                  padding-right: 24px;
                }

                #close-btn {
                  top: 8px;
                  right: 8px;
                }
              }
            }
          }
          @keyframes fadein {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes grow {
            0% {
              transform: translate(-50%, -60%) scale(0.1);
            }
            100% {
              transform: translate(-50%, -60%) scale(1);
            }
          }
        `}
			</style>
		</div>
	);
};
