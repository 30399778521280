import React, { useEffect } from 'react';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer/NewFooter2';
import { VideoPlayer } from 'components/VideoPlayer';
import { SignupModal } from 'components/SignupModal';
import { useDispatchUi, useUi } from 'context/ui';

export const Layout = ({
    showClassCodeInput = false,
    showLoginAndSignup = true,
    hideNavigationMenu = false,
    hideNavbar = false,
    hideFooter = false,
    minHeight = '100vh',
    signupLinkText = 'SIGN UP',
    signupLink = null,
    stepUpNav = false,
    className = '',
    children = null,
    site = null,
    navbarComponent = null,
    navbarComponentMobile = null,
    timesTables = false,
}) => {
    const ui: any = useUi();
    const dispatch: any = useDispatchUi();
    useEffect(() => {
        dispatch({ type: 'CLOSE_ALL' });
    }, []);
    // const popupVideoLinks = [
    //     'https://vimeo.com/574993176', // how it works
    //     'https://vimeo.com/461879172' // founder message
    // ];
    return (
        <>
            <div
                className={`${className} font-Inter relative`}
                style={{ minHeight }}
                data-comp={Layout.displayName}
            >
                {!hideNavbar && (
                    <Header
                        showClassCodeInput={showClassCodeInput}
                        showLoginAndSignup={showLoginAndSignup}
                        signupLink={signupLink}
                        signupLinkText={signupLinkText}
                        hideNavigationMenu={hideNavigationMenu}
                        stepUpNav={stepUpNav}
                        navbarComponent={navbarComponent}
                        navbarComponentMobile={navbarComponentMobile}
                        site={site}
                        timesTables={timesTables}
                    />
                )}
                {children}
                {/*{popupVideoLinks.map(url => (<VideoPlayer key={url} url={url} visible={ui?.playVideo && ui?.videoLink === url} />))}*/}
                {ui?.playVideo && <VideoPlayer key={ui?.videoLink} url={ui?.videoLink} play={true} />}
                {/*{ui?.playVideo && <VideoPlayer url={ui?.videoLink} visible={ui?.playVideo} />}*/}
                {ui?.modalVisible && <SignupModal />}

                {/*
                <div className='absolute inset-0 z-50 default-px'>
                    <div className='w-full h-full m-auto default-max-w border-solid -bg-red-100' />
                    <div className='absolute bottom-0 left-0 top-0 right-[50%] border-0 border-r-1 border-solid' />
                    <div className='absolute bottom-0 left-0 top-0 right-[50%] border-r-[1px] border-solid border-0' />
                    <div className='absolute left-1/2 border-solid border-[1px] top-0 bottom-0 w-6 z-10 h-full -translate-x-1/2' />
                </div>
                */}

            </div>
            {!hideFooter && <Footer hideCTA={false}/>}
        </>
    );
};

Layout.displayName = 'Layout';
